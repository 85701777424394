<template>
    <div id="pageList" class="roleList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
<!--                    <el-col :span="3" class="myColTitle">编码：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入编码模糊匹配查询..." v-model="pageList.queryParam.code"/>-->
<!--                    </el-col>-->
                    <el-col :span="3" class="myColTitle">名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入名称模糊匹配查询..." v-model="pageList.queryParam.name"/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:fixCol v-if="engineParams">
                <el-table-column type="selection" width="55"/>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol v-if="!engineParams">
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="200">
                    <template #default="scope">
                        <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                        </el-button>
                        <el-button @click="permissionHandler(scope.row)" type="text">
                            <div style="color: #27ae60">
                                <i class="el-icon-s-flag"></i>&nbsp;&nbsp;角色授权
                            </div>
                        </el-button>
                        <el-button @click="pageListRef.deleteHandler(scope.row)" type="text">
                            <div style="color:red">
                                <i class="el-icon-delete"></i>&nbsp;&nbsp;删除
                            </div>
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
<!--                <el-table-column prop="F_CODE" label="编码"/>-->
                <el-table-column prop="F_NAME" label="名称"/>
                <el-table-column prop="F_DATA_LEVEL" label="数据权限" :formatter="formatterDataLevel"
                                 :filters="[{ text: '本人', value: '0' },
                                 { text: '本机构', value: '3' }, { text: '本机构及下级机构', value: '4' }]" :filter-method="filterDataLevel"/>
<!--                <el-table-column prop="F_PORTAL_LEVEL" label="门户权限" :formatter="formatterPortalLevel"-->
<!--                                 :filters="[{ text: '编辑', value: '1' }, { text: '查看', value: '0' }]"-->
<!--                                 :filter-method="filterPortalLevel"/>-->
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import RoleListHelper from "./RoleListHelper.js";

    export default RoleListHelper;
</script>

<style scoped>
    .roleList{width: 100%;}
</style> 
